exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-areas-of-work-js": () => import("./../../../src/templates/AreasOfWork.js" /* webpackChunkName: "component---src-templates-areas-of-work-js" */),
  "component---src-templates-chronicle-distributor-js": () => import("./../../../src/templates/ChronicleDistributor.js" /* webpackChunkName: "component---src-templates-chronicle-distributor-js" */),
  "component---src-templates-conference-js": () => import("./../../../src/templates/Conference.js" /* webpackChunkName: "component---src-templates-conference-js" */),
  "component---src-templates-conference-theme-js": () => import("./../../../src/templates/ConferenceTheme.js" /* webpackChunkName: "component---src-templates-conference-theme-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/Event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-distributor-js": () => import("./../../../src/templates/EventsDistributor.js" /* webpackChunkName: "component---src-templates-events-distributor-js" */),
  "component---src-templates-form-js": () => import("./../../../src/templates/Form.js" /* webpackChunkName: "component---src-templates-form-js" */),
  "component---src-templates-news-distributor-js": () => import("./../../../src/templates/NewsDistributor.js" /* webpackChunkName: "component---src-templates-news-distributor-js" */),
  "component---src-templates-organization-js": () => import("./../../../src/templates/Organization.js" /* webpackChunkName: "component---src-templates-organization-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-press-releases-js": () => import("./../../../src/templates/PressReleases.js" /* webpackChunkName: "component---src-templates-press-releases-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/Resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-resources-js": () => import("./../../../src/templates/Resources.js" /* webpackChunkName: "component---src-templates-resources-js" */),
  "component---src-templates-un-meeting-js": () => import("./../../../src/templates/UNMeeting.js" /* webpackChunkName: "component---src-templates-un-meeting-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/Work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

